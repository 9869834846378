import * as React from "react";
import AssetPage from "../../components/asset-page";

const XrpPage = () => (
  <AssetPage
    asset="XRP"
    ticker="XRP"
    description={[
      "XRP is a digital asset that's native to the XRP Ledger—an open-source, permissionless and decentralized blockchain technology.",
      "It's fast, energy efficient, and reliable. With ease of development, low transaction costs, and a knowledgeable community, it provides developers with a strong open-source foundation for executing on the most demanding projects—without hurting the environment.",
    ]}
    features={[
      {
        title: "Low fees",
        text: "At fractions of a penny per transaction, costs are inexpensive enough to enable a wide variety of use cases.",
        // url: "https://support.bifrostwallet.com/en/articles/6545558-wrap-and-delegate-flare",
        // linkText: "How do I wrap FLR?"
      },
      {
        title: "Vibrant Community",
        text: "Developers, validators, users, and businesses make the XRP Ledger better every day.",
        // url: "https://support.bifrostwallet.com/en/articles/6545558-wrap-and-delegate-flare",
        // linkText: "How do I delegate?"
      },
      {
        title: "Public and Decentralized Structure",
        text: "Open source, open to anyone to build on, maintained by the community.",
        // url: "https://support.bifrostwallet.com/en/articles/6908937-how-to-enable-automatic-claiming-in-bifrost-wallet",
        // linkText: "Learn how to enable automatic claiming"
      },
    ]}
  />
);

export default XrpPage;
